.container {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 3.2rem;
}

.connectButton p {
	color: #d61c4e;
	border: 1px solid #d61c4e;
	padding: 0.6rem 1.6rem;
	border-radius: 0.4rem;
	letter-spacing: 1px;
	cursor: pointer;
}

.btnContainer {
	display: flex;
	align-items: center;
	margin-top: 2.4rem;
}

.buttons {
	margin-top: 0.4rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.buttons div span {
	color: white;
	font-size: 1.8rem;
}

.buttons div:nth-child(1) {
	grid-row: span 2;
	border: 2px solid #666;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.buttons div {
	display: flex;
	align-items: center;
	justify-content: center;
	/* padding: 4px 6px; */
}
.buttons div:nth-child(2) {
	border: 2px solid #666;
	border-left: 0;
	border-top-right-radius: 4px;
}

.buttons div:nth-child(3) {
	border: 2px solid #666;
	border-top: 0;
	border-left: 0;
	border-bottom-right-radius: 4px;
}

.buttonClaim {
	margin-left: 2rem;
	margin-top: 2rem;
}

.buttonClaim p {
	color: #777;
	border: 1px solid #777;
	padding: 1rem 1.8rem;
	border-radius: 0.4rem;
	letter-spacing: 1px;
	cursor: pointer;
}

.amount {
	color: white;
}

.amount span {
	color: #888;
}

@media only screen and (min-width: 1024px) {
	.container {
		margin-top: 0;
	}
}

.container {
	padding: 1rem 1.2rem;
}

.container .container__mint {
	background: rgb(21, 39, 33);
	max-width: 80rem;
	margin: 0 auto;
	margin-top: 3rem;
	border-radius: 0.4rem;
	padding: 4rem 3rem;
}

.container .container__mint .container__mintBox {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footer {
	margin-top: 4rem;
}
.footer p {
	font-size: 1.1rem;
	text-align: center;
}

@media only screen and (min-width: 546px) {
}

@media only screen and (min-width: 1024px) {
	.container .container__mint .container__mintBox {
		flex-direction: row;
		justify-content: space-around;
	}
}

h6,
p,
span {
	font-family: Arial, Helvetica, sans-serif;
}
